export const WORDS = [
  'covid',
  'wimwi',
  'ganja',
  'bread',
  'chaos',
  'convo',
  'garba',
  'globe',
  'arbit',
  'vodka',
  'hitch',
  'gandu',
  'booze',
  'crush',
  'despo',
  'ditch',
  'dabba',
  'hawas',
  'peace',
  'junta',
  'sutta',
  'pgpii',
  'enthu',
  'matka',
  'ecell',
  'pyaar',
  'tempo',
  'vella',
  'shopc',
  'ankur',
  'termi',
  'chapa',
  'mktii',
  'funda',
  'fakka',
  'kholu',
  'bandi',
  'banda',
  'lassu',
  'batti',
  'maggu',
  'lasna',
  'gyaan',
  'bulla',
  'niche',
  'acads',
  'ditto',
  'smile',
  'scene',
  'bajar',
  'cribs',
  'juice',
  'klmdc',
  'tower',
  'enthu',
  'fakka',
  'pakau',
  'satti',
  'atthi',
  'nehli',
  'dassa',
  'banda',
  'matki',
  'baddy',
  'fattu',
  'buddy',
  'mallu',
  'senti',
  'hagga',
  'behen',
  'fraud',
  'laath',
    ]
